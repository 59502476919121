import { Links, Scripts, LiveReload } from '@ubo/losse-sjedel'
import { AdminPanel, LosseLink } from '@ubo/losse-sjedel'

export default function PageNotFound() {
  return (
    <html lang="nl">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Links />
      </head>
      <body>
        <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div className="max-w-max mx-auto">
            <main className="sm:flex">
              <p className="text-4xl font-extrabold font-nord text-outrageous-orange sm:text-5xl">404</p>
              <div className="sm:ml-6">
                <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                  <h1 className="text-4xl font-extrabold font-nord text-gray-900 tracking-tight sm:text-5xl">Pagina niet gevonden</h1>
                  <p className="mt-1 text-base font-prompt text-gray-500">Controleer de URL in de adresbalk en probeer het opnieuw.</p>
                </div>
                <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                  <LosseLink
                    to="/"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-outrageous-orange hover:bg-sprout focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-outrageous-orange"
                  >
                    Ga naar home
                  </LosseLink>
                  <LosseLink
                    to="/contact"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-sprout hover:bg-outrageous-orange focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-outrageous-orange"
                  >
                    Contact
                  </LosseLink>
                </div>
              </div>
            </main>
          </div>
        </div>
        <Scripts />
        <LiveReload />
        <AdminPanel />
      </body>
    </html>
  )
}
